<!--信息审核-数据接入-->
<template>
  <section>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-cascader
              v-model="filter.owner"
              @change="getData"
              placeholder="所属单位"
              :options="customerTree"
              :props="orgProps"
              clearable
              filterable
            />
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="排放状态"
              v-model="filter.status"
              @change="getData"
              clearable
            >
              <el-option label="达标" value="NORMAL" />
              <el-option label="超标" value="EXCESS" />
              <el-option label="离线" value="DOWN" />
              <el-option label="异常离线" value="OFF" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="整体状态"
              v-model="filter.onlineStatus"
              @change="getData"
              clearable
            >
              <el-option label="绿" value="1" />
              <el-option label="灰" value="2" />
              <el-option label="黑" value="3" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleRefresh">刷新</el-button>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >导出</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="columnVisible"
              v-if="filter.typ === 2"
              multiple
              placeholder="请选择"
              collapse-tags
              @change="afterChangeColumnVisiable"
            >
              <el-button @click="selectAll" size="mini" type="text" style="margin-left : 15px">全选</el-button>
              <el-option
                v-for="item in columnVisibleOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>

            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="columnVisible"
              v-if="filter.typ === 1"
              multiple
              placeholder="请选择"
              collapse-tags
              @change="afterChangeColumnVisiable"
            >
              <el-button @click="selectAll" size="mini" type="text" style="margin-left : 15px">全选</el-button>
              <el-option
                v-for="item in columnVisibleOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <table class="device-info">
        <tr>
          <th>监测点数</th>
          <th>{{ filter.typ == 1 ? "监控设备数" : "监测设备数" }}</th>
          <th>当日在线数</th>
        </tr>
        <tr>
          <th>{{ localeNum }}</th>
          <th>{{ deviceNum }}</th>
          <th>{{ onlineNum }}</th>
        </tr>
      </table>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          ref="table"
          :data="datalist.content"
          @sort-change="handleSort"
          size="mini"
          :max-height="clientHeight"
          border
          highlight-current-row
          v-loading="loading"
          style="width: 100%"
        >
          <el-table-column type="index" label="#" align="center" width="55" />
          <el-table-column
            prop="Locale"
            v-if="columnVisible.includes('1')"
            label="监测点"
            :width="filter.typ === 2 ? 120 : ''"
            show-overflow-tooltip
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            prop="Addr"
            v-if="columnVisible.includes('2')"
            label="安装地址"
            show-overflow-tooltip
            :min-width="filter.typ === 2 ? 200 : ''"
            header-align="center"
          />
          <el-table-column
            prop="Id"
            v-if="columnVisible.includes('3')"
            sortable="custom"
            label="设备编码"
            show-overflow-tooltip
            :min-width="filter.typ === 2 ? 200 : ''"
            align="center"
            header-align="center"
          />
          <!-- <el-table-column prop='Owner' :formatter="customerFormatter" label='所属单位' show-overflow-tooltip width='230' header-align='center' /> -->
          <el-table-column
            prop="AcquitAt"
            v-if="columnVisible.includes('4')"
            :formatter="dateFormat"
            sortable
            label="采集时间"
            show-overflow-tooltip
            :min-width="filter.typ === 2 ? 200 : ''"
            align="center"
            header-align="center"
          />
          <el-table-column
            v-if="columnVisible.includes('5')"
            width="90"
            label="风机状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ FanFilterStatusFormatter(scope.row, 0) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('6')"
            width="100"
            label="风机电流"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{electricityFormatter(scope.row, 'fan')}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('7')"
            width="90"
            label="净化器状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ FanFilterStatusFormatter(scope.row, 1) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('8')"
            width="100"
            label="净化器电流"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{electricityFormatter(scope.row, 'filter')}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('9')"
            width="60"
            label="风速"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{(scope.row.Status === "OFF" || scope.row.Velocity === 0 )? "-" : scope.row.Velocity}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('19')"
            width="60"
            label="风量"
            align="center"
            header-align="center"
          ></el-table-column>
          <el-table-column
            v-if="columnVisible.includes('10')"
            width="60"
            label="温度"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{(scope.row.Status === "OFF" || scope.row.Temperature === 0) ? "-" : scope.row.Temperature}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="60"
            v-if="columnVisible.includes('11')"
            label="湿度"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{(scope.row.Status === "OFF" || scope.row.Moisture === 0) ? "-" : scope.row.Moisture}}</span>
            </template>
          </el-table-column>
          <template v-if="filter.typ === 2">
            <el-table-column
              v-if="columnVisible.includes('12')"
              prop="EmissionsConc"
              label="油烟实时浓度(mg/m³)"
              min-width="140"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.Status === "OFF" ? "-" : scope.row.EmissionsConc}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('13')"
              prop="CEmissions"
              label="油烟折算浓度(mg/m³)"
              min-width="140"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.Status === "OFF" ? "-" : scope.row.CEmissions}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('14')"
              prop="GranuleConc"
              label="颗粒物实时浓度(mg/m³)"
              min-width="140"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.Status === "OFF" ? "-" : scope.row.GranuleConc}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('15')"
              prop="CGranule"
              label="颗粒物折算浓度(mg/m³)"
              min-width="140"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.Status === "OFF" ? "-" : scope.row.CGranule}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('16')"
              prop="HydrocarbonConc"
              label="非甲烷总烃实时浓度(mg/m³)"
              min-width="140"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.Status === "OFF" ? "-" : scope.row.HydrocarbonConc}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('17')"
              prop="CHydrocarbon"
              label="非甲烷总烃折算浓度(mg/m³)"
              min-width="140"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{scope.row.Status === "OFF" ? "-" : scope.row.CHydrocarbon}}</span>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            v-if="columnVisible.includes('18')"
            label="排放状态"
            width="80"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <img height="40" width="40" :src="getImg('Status', scope.row.Status)" alt="排放状态" />
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('20')"
            label="整体状态"
            width="80"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <img height="40" width="40" :src="getImg('OnlineStatus', scope.row.OnlineStatus)" alt="整体状态" />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            header-align="center"
            width="140"
          >
            <template slot-scope="scope">
              <i
                title="查看每分钟数据"
                @click="handleShowIntime(scope.row, false)"
                class="opt el-icon-notebook-2"
              />
              <i
                title="查看10min浓度"
                v-if="filter.typ === 2"
                @click="handleShowIntime(scope.row, true)"
                class="opt el-icon-s-data"
              />
              <i
                title="记录"
                @click="handleShowHistory(scope.row)"
                class="opt el-icon-s-order"
              />
            </template>
          </el-table-column>
          <el-table-column
            label="审核"
            align="center"
            header-align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="handleShowQualify(scope.row)">审核</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar">
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'size', true)"
          @current-change="(v) => handlePageChange(v, 'page', true)"
          :current-page.sync="filter.page"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="datalist.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <!--编辑/分钟数据/10min数据界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      width="85%"
      @close="handlerClose"
    >
      <span slot="title"
      ><strong>{{ owner }}</strong></span
      >
      <el-form :inline="true" size="mini">
        <el-form-item v-if="showTyp">
          <el-select placeholder="时间选择" v-model="timeType">
            <el-option label="按天" value="1" />
            <el-option label="时间段" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="timeType == 1">
          <el-date-picker
            @change="handleDateSel"
            v-model="selDate"
            type="date"
            value-format="timestamp"
            placeholder="日期选择"
          />
        </el-form-item>
        <template v-else-if="timeType == 2">
          <el-form-item>
            <el-date-picker
              type="date"
              v-model="startDate"
              value-format="yyyy-MM-dd"
              placeholder="开始日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="date"
              v-model="endDate"
              value-format="yyyy-MM-dd"
              placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleDateSel"
            >查询</el-button
            >
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleExport"
          >导出</el-button
          >
          <!-- <el-button type="danger" v-if="showTyp"  @click="ExportSavePdf('文件名称','到处时间')">导出PDF</el-button> -->
        </el-form-item>
      </el-form>
      <template v-if="showTyp">
        <div id="pdfCentent">
          <div class="chart-line">
            <chart :options="tenMinOption" autoresize />
          </div>
        </div>
      </template>
      <template v-else>
        <el-table
          :data="intimeData.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="55"
          />
          <el-table-column
            prop="acquit_at"
            :formatter="dateFormat"
            label="采集时间"
            width="140"
            align="center"
            header-align="center"
          />
          <el-table-column
            label="风机状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.fan_status | linkFilter}}</span>
            </template>
          </el-table-column>
          <el-table-column
            :formatter="ffFormatter"
            label="净化器状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{scope.row.filter_status | linkFilter}}</span>
            </template>
          </el-table-column>
          <template v-if="filter.typ === 2">
            <el-table-column
              prop="emissions_conc"
              label="油烟浓度(mg/m³)"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.emissions_conc > 2 ? 'red' : ''">{{scope.row.emissions_conc}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="granule_conc"
              label="颗粒物浓度(mg/m³)"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.granule_conc > 5 ? 'red' : ''">{{scope.row.granule_conc}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="hydrocarbon_conc"
              label="非甲烷总烃浓度(mg/m³)"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.hydrocarbon_conc > 10 ? 'red' : ''">{{scope.row.hydrocarbon_conc}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop='velocity' label='流速' align='center' header-align='center' />
            <el-table-column prop='temperature' label='温度' align='center' header-align='center' />
            <el-table-column prop='moisture' label='湿度' align='center' header-align='center' /> -->
          </template>
          <template v-else>
            <el-table-column
              prop="poweroff"
              label="电源关闭指令"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc1"
              label="电源1故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv1"
              label="电源1电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc2"
              label="电源2故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv2"
              label="电源2电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc3"
              label="电源3故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv3"
              label="电源3电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc4"
              label="电源4故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv4"
              label="电源4电流值"
              align="center"
              header-align="center"
            />
          </template>
          <el-table-column
            label="设备状态"
            width="70"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <p v-if="scope.row.emissions_conc*1 > 2 || scope.row.granule_conc*1 > 5 || scope.row.hydrocarbon_conc*1 > 10">超标</p>
              <p v-else>达标</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'intimeSize', false)"
          @current-change="(v) => handlePageChange(v, 'intimePage', false)"
          :current-page.sync="filter.intimePage"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="intimeData.total"
          style="display: inline-block; margin-left: 15px"
        />
      </template>
    </el-dialog>
    <!-- 记录界面 -->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible4History"
      width="35%"
      @close="handlerClose"
    >
      <p>1.审核记录：</p>
      <el-table
        :data="history.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column
          prop="ExternalName"
          label="监测点名称"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AuditAt"
          label="审核时间"
          align="center"
          header-align="center"
          :formatter="auditAtFormat"
        />
        <el-table-column
          prop="Handler"
          label="审核人"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Result"
          label="审核结果"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Cause"
          label="原因"
          align="center"
          header-align="center"
        />
      </el-table>
      <p>2.下线记录：</p>
      <el-table
        :data="history.content_offline"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column
          prop="ExternalName"
          label="监测点名称"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AuditAt"
          label="下线时间"
          align="center"
          header-align="center"
          :formatter="auditAtFormat"
          width="100"
        />
        <el-table-column
          prop="Handler"
          label="下线人"
          align="center"
          header-align="center"
          width="100"
        />
        <el-table-column
          prop="Cause"
          label="下线原因"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-dialog>
    <!-- 审核界面 -->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible4Qualify"
      width="25%"
      @close="handlerClose"
    >
      <el-form
        size="mini"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        :model="formData"
        @close="handlerClose"
      >
        <el-form-item prop="Cause" label="请备注原因">
          <el-input v-model="formData.Cause"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="qualify(true)">审核通过</el-button>
          <el-button type="primary" @click="qualify(false)">审核不通过</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </section>
</template>

<script>
  import ECharts from 'vue-echarts'
  import 'echarts/lib/chart/line'
  import 'echarts/lib/component/tooltip'
  import 'echarts/lib/component/markLine'
  import { mapState } from 'vuex'
  import {
    dateFormater,
    join,
    underline,
    getPname,
    export_json,
    referToMap,
    maintenanceStatusFormatter,
    maintenanceTypeFormatter
  } from '@/util/index'
  import _1 from '@/asset/img/1.png'
  import _2 from '@/asset/img/2.png'
  import _3 from '@/asset/img/3.png'
  import _4 from '@/asset/img/4.png'
  import _5 from '@/asset/img/5.png'

  export default {
    name: 'DataAccess',
    components: {
      chart: ECharts
    },
    data() {
      return {
        rules: {
          Cause: [
            { required: true, message: '请备注原因', trigger: 'blur' }
          ]
        },
        mapCenter: { lng: 120.306305, lat: 30.424877 },
        mapZoom: 14,
        fileList: [],
        referMap: {},
        changeState: false,
        index: 0, // 记录更新index
        sels: [], // 列表选中列
        now: null,
        exportLoading: false,
        loading: false,
        showTyp: true,
        history: {
          content: [],
          content_offline: []
        },
        datalist: {},
        formData: {
          Cause: '',
          StatusOfRecord: ''
        },
        intimeData: {},
        selDate: null,
        owner: null,
        mn: null,
        onlineNum: 0,
        localeNum: 0,
        deviceNum: 0,
        localeScope: null,
        EmissionsSill: null,
        GranuleSill: null,
        HydrocarbonSill: null,
        LinkStatus: null,
        exportData: null,
        timeType: '1',
        startDate: null,
        endDate: null,
        columnVisibleOptions1: [
          {
          value: '1',
          label: '监测点'
        }, {
          value: '2',
          label: '安装地址'
        }, {
          value: '3',
          label: '设备编码'
        }, {
          value: '4',
          label: '采集时间'
        }, {
          value: '5',
          label: '风机状态'
        }, {
          value: '6',
          label: '风机电流'
        }, {
          value: '7',
          label: '净化器状态'
        }, {
          value: '8',
          label: '净化器电流'
        }, {
          value: '9',
          label: '风速'
        }, {
          value: '10',
          label: '温度'
        }, {
          value: '11',
          label: '湿度'
        }, {
          value: '12',
          label: '油烟实时浓度'
        }, {
          value: '13',
          label: '油烟折算浓度'
        }, {
          value: '14',
          label: '颗粒物实时浓度'
        }, {
          value: '15',
          label: '颗粒物折算浓度'
        }, {
          value: '16',
          label: '非甲烷总烃实时浓度'
        }, {
          value: '17',
          label: '非甲烷总烃折算浓度'
        }, {
          value: '18',
          label: '设备状态'
        }, {
          value: '19',
          label: '风量'
        }, {
          value: '20',
          label: '整体状态'
        }],
        columnVisibleOptions2: [
          {
          value: '1',
          label: '监测点'
        }, {
          value: '2',
          label: '安装地址'
        }, {
          value: '3',
          label: '设备编码'
        }, {
          value: '4',
          label: '采集时间'
        }, {
          value: '5',
          label: '风机状态'
        }, {
          value: '6',
          label: '风机电流'
        }, {
          value: '7',
          label: '净化器状态'
        }, {
          value: '8',
          label: '净化器电流'
        }, {
          value: '9',
          label: '风速'
        }, {
          value: '10',
          label: '温度'
        }, {
          value: '11',
          label: '湿度'
        }, {
          value: '18',
          label: '设备状态'
        }, {
          value: '19',
          label: '风量'
        }, {
          value: '20',
          label: '整体状态'
        }],
        tenMinOption: {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['油烟浓度', '颗粒物浓度', '非甲烷总烃浓度']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: [],
            boundaryGap: false,
            axisLabel: {
              interval: 17
            }
          },
          yAxis: {
            type: 'value',
            // min: 5,
            // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
            name: '平均浓度mg/m³'
          },
          series: [
            {
              name: '油烟浓度',
              type: 'line',
              data: [],
              itemStyle: {
                normal: {
                  color: '#00A1FF',
                  lineStyle: {
                    type: 'solid',
                    color: '#00A1FF'
                  }
                }
              },
              markLine: {
                symbol: 'none',
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: 'solid',
                      color: '#00A1FF'
                    },
                    label: {
                      position: 'start',
                      formatter: '{c} '
                    }
                  }
                },
                data: [
                  {
                    name: '阈值',
                    yAxis: 1
                  }
                ]
              }
            },
            {
              name: '颗粒物浓度',
              type: 'line',
              data: [],
              itemStyle: {
                normal: {
                  color: '#F5B049',
                  lineStyle: {
                    type: 'solid',
                    color: '#F5B049'
                  }
                }
              },
              markLine: {
                symbol: 'none',
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: 'solid',
                      color: '#F5B049'
                    },
                    label: {
                      position: 'start',
                      formatter: '{c} '
                    }
                  }
                },
                data: [
                  {
                    name: '阈值',
                    yAxis: 1
                  }
                ]
              }
            },
            {
              name: '非甲烷总烃浓度',
              type: 'line',
              data: [],
              itemStyle: {
                normal: {
                  color: '#009999',
                  lineStyle: {
                    type: 'solid',
                    color: '#009999'
                  }
                }
              },
              markLine: {
                symbol: 'none',
                itemStyle: {
                  normal: {
                    lineStyle: {
                      type: 'solid',
                      color: '#009999'
                    },
                    label: {
                      position: 'start',
                      formatter: '{c} '
                    }
                  }
                },
                data: [
                  {
                    name: '阈值',
                    yAxis: 1
                  }
                ]
              }
            }
          ]
        },
        dlg: {
          visible: false,
          visible4History: false,
          visible4Qualify: false,
          state: null,
          title: ''
        },
        filter: {
          onlineStatus: null,
          name: null,
          owner: [],
          by: 'status',
          mode: 'asc',
          status: null,
          typ: 2,
          field: 'name',
          page: 1,
          intimePage: 1,
          size: 20,
          intimeSize: 20,
          pageSizes: [20, 30, 50]
        },
        columnVisible: ['1', '2', '4', '5', '7', '12', '13', '14', '15', '16', '17', '18', '20'],
        FanFilterStatusOptions: {
          0: '关',
          1: '开',
          2: '关',
          3: '异常'
        }
      }
    },
    computed: {
      ...mapState({
        orgProps: (state) =>
          Object.assign({}, state.props, { label: 'Org', checkStrictly: true })
      }),
      ...mapState([
        'props',
        'clientHeight',
        'customer',
        'customerTree',
        'locale'
      ])
    },
    filters: {
      linkFilter(type) {
        const obj = {
          1: '开',
          2: '关',
          3: '异常'
        }
        return obj[type]
      }
    },
    created() {
      this.now = Date.parse(new Date()) / 1000
      this.$get('admin/listCascadeReferParam', { pcode: 'DEVICE_TYPE', code: 'DEVICE_SUBTYPE', c_code: 'DEVICE_MODEL' }).then(res => {
        this.referMap = referToMap(res, 'Id', 'Name')
        res.forEach(e => {
          if (e.Pid !== '') {
            e.Pid = '/' + e.Pid + '/'
          }
        })
      })
      this.getData()
    },
    methods: {
      maintenanceStatusFormatter,
      maintenanceTypeFormatter,
      openExportSizeInput() {
        this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*$/,
          inputErrorMessage: '只能填入数字'
        }).then(({ value }) => {
          this.handleDownload(parseInt(value))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消'
          })
        })
      },
      qualify(result) {
        const payload = {
          Id: this.formData.Lid,
          StatusOfRecord: ''
        }
        payload.StatusOfRecord = result ? 'NORMAL' : 'OFFLINE'
        this.$post('admin/auditLocale', payload).then(() => {
          this.getData()
          this.dlg.visible4Qualify = false
        })
      },
      handleShowQualify(row) {
        this.dlg.title = '审核'
        this.dlg.visible4Qualify = true
        this.formData = {
          ...row,
          ...this.formData
        }
      },
      handleShowHistory(row) {
        this.dlg.title = '记录'
        this.dlg.visible4History = true
        this.loading = true
        const param = {
          type: 'Locale',
          id: row.Lid
        }
        this.$get('admin/listRecord', param).then((res) => {
          this.loading = false
          this.history = Object.assign({}, res)
        })
      },
      afterChangeColumnVisiable() {
        this.$nextTick(() => {
          this.$refs['table'].doLayout()
        })
      },
      electricityFormatter(row, type) {
        switch (type) {
          case 'fan':
            if (row.FanStatus.toString() === '1') {
              return row.fan_current
            } else {
              return '-'
            }
          case 'filter':
            if (row.FilterStatus.toString() === '1' || row.FilterStatus.toString() === '3') {
              return row.pur_current
            } else {
              return '-'
            }
        }
      },
      selectAll() {
        this.columnVisible = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
        this.afterChangeColumnVisiable()
      },
      handleDateSel(v) {
        if (v !== null) {
          if (this.showTyp) {
            this.getTenMinData()
          } else {
            this.getIntimeData()
          }
        }
      },
      handleSort(c) {
        if (c.order) {
          if (c.prop === 'DataTime') {
            this.filter.by = 'acquit_at'
          } else {
            this.filter.by = underline(c.prop)
          }
          this.filter.mode = c.order.replace(/ending/g, '')
        }
        this.getData()
      },
      /**
       * @return {string}
       */
      FanFilterStatusFormatter(row, type) {
        switch (type) {
          case 0:
            return this.FanFilterStatusOptions[row.FanStatus]
          case 1:
            return this.FanFilterStatusOptions[row.FilterStatus]
        }
      },
      managementDateFormatter(r, c) {
        return dateFormater(r[c.property], false, false)
      },
      dateFormat(r, c) {
        return dateFormater(r[c.property], true)
      },
      auditAtFormat(r, c) {
        return new Date(r[c.property]).toLocaleDateString('zh-CN').replaceAll('/', '-')
      },
      fanFilterFormatter(r) {
        if (r.LinkStatus === 1) {
          return '开' // r.Status === 'EXCESS' || r.Status === 'NORMAL' || r.Status === 'FAULT' ? '开' : '-'
        }
        return '-'
      },
      ffFormatter(r) {
        if (this.LinkStatus === 1) {
          return '开'
        }
        return '-'
      },
      modelFormatter(r, c) {
        return this.referMap['c' + r[c.property]]
      },
      subTypeFormatter(r, c) {
        return this.referMap['d' + r[c.property]]
      },
      customerFormatter(r, c) {
        return getPname(this.customerTree, r[c.property], 'Org').join('/')
      },
      getIntimeData() {
        this.loading = true
        this.$post('admin/listIntimeData', {
          StartAt: (this.filter.intimePage - 1) * this.filter.intimeSize,
          Size: this.filter.intimeSize,
          mn: this.mn,
          typ: this.filter.typ,
          at: this.selDate / 1000
        }).then((res) => {
          this.loading = false
          this.intimeData = res
          if (
            res &&
            res.content &&
            res.content.length !== 0 &&
            this.selDate === null
          ) {
            this.selDate = new Date(
              new Date(res.content[0]['acquit_at'] * 1000).setHours(0, 0, 0, 0)
            ).getTime()
          }
        })
      },
      getLast10Days() {
        const day = new Date()
        const yy = day.getFullYear()
        const mm =
          day.getMonth() + 1 < 10 ? `0${day.getMonth() + 1}` : day.getMonth() + 1
        const dd = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate()
        const last = new Date(new Date().getTime() - 10 * 24 * 3600 * 1000)
        const lyy = last.getFullYear()
        const lmm =
          last.getMonth() + 1 < 10
            ? `0${last.getMonth() + 1}`
            : last.getMonth() + 1
        const ldd = last.getDate() < 10 ? `0${last.getDate()}` : last.getDate()
        this.startDate = `${lyy}-${lmm}-${ldd}`
        this.endDate = `${yy}-${mm}-${dd}`
      },
      getTenMinData() {
        this.loading = true
        let tpl, url
        if (this.timeType.toString() === '1') {
          tpl = {
            mn: this.mn,
            at: this.selDate / 1000
          }
          url = 'admin/listTenMinData'
        } else if (this.timeType.toString() === '2') {
          tpl = {
            mn: this.mn,
            AcquitAtTimeBegin: this.startDate,
            AcquitAtTimeEnd: this.endDate
          }
          url = 'admin/queryTenMinData'
        }
        this.$post(url, tpl).then((res) => {
          this.loading = false
          this.exportData = []
          this.tenMinOption.series[0]['data'] = []
          this.tenMinOption.series[0]['markLine']['data'][0][
            'yAxis'
            ] = this.EmissionsSill
          this.tenMinOption.xAxis['data'] = []
          this.tenMinOption.series[1]['data'] = []
          this.tenMinOption.series[1]['markLine']['data'][0][
            'yAxis'
            ] = this.GranuleSill
          this.tenMinOption.series[2]['data'] = []
          this.tenMinOption.series[2]['markLine']['data'][0][
            'yAxis'
            ] = this.HydrocarbonSill
          if (res && res.length !== 0) {
            if (this.timeType * 1 === 1) {
              if (this.selDate === null) {
                this.selDate = new Date(
                  new Date(res[0]['acquit_at'] * 600 * 1000).setHours(0, 0, 0, 0)
                ).getTime()
              }
              let data
              const t = new Date(this.selDate)
              t.setHours(0, 0, 0, 0)
              const endTime = Math.floor(t.getTime() / 600000) + 143
              let at
              for (var i = Math.floor(t.getTime() / 600000); i <= endTime; i++) {
                data = res.find((e) => e.acquit_at === i)
                at = dateFormater(i * 600, true, true)
                if (!data) {
                  data = {
                    emissions_conc: 0,
                    granule_conc: 0,
                    hydrocarbon_conc: 0,
                    counter: 1
                  }
                } else {
                  this.exportData.push({
                    采集时间: at,
                    油烟平均浓度: (data.emissions_conc ).toFixed(2),
                    颗粒物平均浓度: (data.granule_conc ).toFixed(2),
                    非甲烷总烃平均浓度: (
                      data.hydrocarbon_conc 
                    ).toFixed(2)
                  })
                }
                this.tenMinOption.series[0]['data'].push(
                  (data.emissions_conc ).toFixed(2)
                )
                this.tenMinOption.series[1]['data'].push(
                  (data.granule_conc).toFixed(2)
                )
                this.tenMinOption.series[2]['data'].push(
                  (data.hydrocarbon_conc).toFixed(2)
                )
                this.tenMinOption.xAxis['data'].push(at)
              }
            } else if (this.timeType * 1 === 2) {
              let data
              const begin = new Date(this.startDate)
              begin.setHours(0, 0, 0, 0)
              const end = new Date(this.endDate)
              end.setHours(0, 0, 0, 0)
              const endTime = Math.floor(end.getTime() / 600000)
              let at
              this.tenMinOption.xAxis['axisLabel']['interval'] = 287
              for (
                var i = Math.floor(begin.getTime() / 600000);
                i <= endTime;
                i++
              ) {
                data = res.find((e) => e.acquit_at === i)
                at = dateFormater(i * 600, true, false)
                if (!data) {
                  data = {
                    emissions_conc: 0,
                    granule_conc: 0,
                    hydrocarbon_conc: 0,
                    counter: 1
                  }
                } else {
                  this.exportData.push({
                    采集时间: at,
                    油烟平均浓度: (data.emissions_conc).toFixed(2),
                    颗粒物平均浓度: (data.granule_conc ).toFixed(2),
                    非甲烷总烃平均浓度: (
                      data.hydrocarbon_conc 
                    ).toFixed(2)
                  })
                }
                this.tenMinOption.series[0]['data'].push(
                  (data.emissions_conc ).toFixed(2)
                )
                this.tenMinOption.series[1]['data'].push(
                  (data.granule_conc).toFixed(2)
                )
                this.tenMinOption.series[2]['data'].push(
                  (data.hydrocarbon_conc).toFixed(2)
                )
                this.tenMinOption.xAxis['data'].push(at)
              }
            }
            this.$forceUpdate()
          }
        })
      },
      handleShowIntime(r, showTyp) {
        this.timeType = '1'
        this.mn = r.Id
        this.showTyp = showTyp
        this.owner = getPname(this.customerTree, r.Owner, 'Org').join('/')
        if (showTyp) {
          this.owner += '-10min浓度曲线'
          this.EmissionsSill = r.EmissionsSill
          this.GranuleSill = r.GranuleSill
          this.HydrocarbonSill = r.HydrocarbonSill
          this.getLast10Days()
          this.getTenMinData()
        } else {
          this.owner += '-分钟采集数据'
          this.LinkStatus = r.LinkStatus
          this.getIntimeData()
        }
        this.dlg.visible = true
      },
      handleExport() {
        if (this.showTyp) {
          export_json(
            dateFormater(this.selDate / 1000, false) + '-' + this.mn,
            this.exportData,
            {
              采集时间: '采集时间',
              油烟平均浓度: '油烟平均浓度',
              颗粒物平均浓度: '颗粒物平均浓度',
              非甲烷总烃平均浓度: '非甲烷总烃平均浓度'
            }
          )
        } else {
          this.$download('admin/exportIntimeData', {
            mn: this.mn,
            typ: this.filter.typ,
            at: this.selDate / 1000
          })
        }
      },
      getImg(type, status) {
        if (type === 'Status') {
          switch (status) {
            case 'NORMAL':
              return _1
            case 'EXCESS':
              return _2
            case 'DOWN':
              return _3
            case 'OFF':
              return _4
            case 'ALARM':
              return _5
            default:
              return _4
          }
        } else if (type === 'OnlineStatus') {
          switch (status) {
            case 1:
              return _1
            case 2:
              return _3
            case 3:
              return _4
            default:
              return _4
          }
        } else {
          return _4
        }
      },
      getData: function() {
        this.loading = true
        var para = {
          StartAt: (this.filter.page - 1) * this.filter.size,
          Size: this.filter.size,
          Typ: this.filter.typ,
          SortBy: this.filter.by,
          SortMode: this.filter.mode,
          Param: {
            StatusOfRecord: 'OFFLINE,REST'
          }
        }
        if (this.filter.owner.length !== 0) {
          para.Param['Owner'] = join(this.filter.owner)
        }
        if (this.filter.status) {
          para.Param['Status'] = this.filter.status
        }
        if (this.filter.onlineStatus) {
          para.Param['OnlineStatus'] = this.filter.onlineStatus
        }
        this.$post('admin/listDataIntime', para).then((res) => {
          if (res) {
            if (para.StartAt === 0) {
              this.localeNum = res.localeNum
              this.deviceNum = res.deviceNum
              this.datalist.total = res.total
            }
            this.onlineNum = res.onlineNum
            this.datalist.content = res.content
          }
          this.loading = false
        })
      },
      handleRefresh() {
        this.filter.page = 1
        this.getData()
      },
      handlePageChange(val, field, typ) {
        this.filter[field] = val
        if (typ) {
          this.getData()
        } else {
          this.getIntimeData()
        }
      },
      handlerClose() {
        this.selDate = null
        this.formData = {
          Cause: '',
          Status: ''
        }
        this.history = {
          content: [],
          content_offline: []
        }
        this.filter.intimePage = 1
        this.handleRefresh()
      },
      handleDownload(exportSize) {
        this.exportLoading = true
        const statusMap = {
          1: '开',
          0: '关',
          2: '未连接'
        }
        const status = {
          'NORMAL': '达标',
          'EXCESS': '超标',
          'DOWN': '离线',
          'OFF': '异常离线'
        }
        var para = {
          StartAt: 0,
          Size: exportSize,
          Typ: this.filter.typ,
          SortBy: this.filter.by,
          SortMode: this.filter.mode
        }
        para.Param = {
          StatusOfRecord: 'OFFLINE,REST',
          IsDownload: true
        }
        if (this.filter.owner.length !== 0) {
          para.Param['Owner'] = join(this.filter.owner)
        }
        if (this.filter.status) {
          para.Param['Status'] = this.filter.status
        }
        this.$post('admin/listDataIntime', para).then(res => {
          const dataArr = res.content.map(v => {
            const newV = {
              监测点: v.Locale,
              设备编号: v.Id,
              安装地址: v.Addr,
              采集时间: dateFormater(v.AcquitAt, true),
              风机状态: v.Status === 'OFF' ? '-' : statusMap[v.FanStatus],
              净化器状态: v.Status === 'OFF' ? '-' : statusMap[v.FilterStatus],
              风速: v.Status === 'OFF' ? '-' : v.Velocity ? scope.row.Velocity : '-',
              温度: v.Status === 'OFF' ? '-' : v.Temperature ? scope.row.Temperature : '-',
              湿度: v.Status === 'OFF' ? '-' : v.Moisture ? scope.row.Moisture : '-',
              '油烟实时浓度(mg/m³)': v.Status === 'OFF' ? '-' : v.EmissionsConc,
              '油烟折算浓度(mg/m³)': v.Status === 'OFF' ? '-' : v.CEmissions,
              '颗粒物实时浓度(mg/m³)': v.Status === 'OFF' ? '-' : v.GranuleConc,
              '颗粒物折算浓度(mg/m³)': v.Status === 'OFF' ? '-' : v.CGranule,
              '非甲烷总烃实时浓度(mg/m³)': v.Status === 'OFF' ? '-' : v.HydrocarbonConc,
              '非甲烷总烃折算浓度(mg/m³)': v.Status === 'OFF' ? '-' : v.CHydrocarbon,
              设备状态: status[v.Status]
            }
            return newV
          })
          export_json('数据接入审核', dataArr, {
            监测点: '监测点',
            设备编号: '设备编号',
            安装地址: '安装地址',
            采集时间: '采集时间',
            风机状态: '风机状态',
            净化器状态: '净化器状态',
            风速: '风速',
            温度: '温度',
            湿度: '湿度',
            '油烟实时浓度(mg/m³)': '油烟实时浓度(mg/m³)',
            '油烟折算浓度(mg/m³)': '油烟折算浓度(mg/m³)',
            '颗粒物实时浓度(mg/m³)': '颗粒物实时浓度(mg/m³)',
            '颗粒物折算浓度(mg/m³)': '颗粒物折算浓度(mg/m³)',
            '非甲烷总烃实时浓度(mg/m³)': '非甲烷总烃实时浓度(mg/m³)',
            '非甲烷总烃折算浓度(mg/m³)': '非甲烷总烃折算浓度(mg/m³)',
            设备状态: '设备状态'
          })
          this.exportLoading = false
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
  table.device-info {
    display: table;
  }
  .red{
    color: red;
  }
</style>
